import { useContext, useState } from "react";
import { DashboardContext } from "states/DashboardContext";
import CencusButton from "ui/elements/census/CencusButton";
import { VisualsStateContext } from "./VisualsStateContext";
import TableauEmbed from "./TableauEmbed";
import Handsontable from "handsontable";
import { HotTable } from "@handsontable/react";
import "handsontable/dist/handsontable.full.min.css";
import * as XLSX from "xlsx"; // Import XLSX to read Excel files
import SiteEmbed from "./SiteEmbed";

function DisabilityVisual() {
  const words = useTranslations();
  const [selectedButton, setSelectedButton] = useState(0);
  const handleSelectedButtonChange = (index) => {
    setSelectedButton(index);

    if (index===2){
        loadExcelData()
    }
  }
  const { visualsState } = useContext(VisualsStateContext);
  const [excelData, setExcelData] = useState([]);

  const loadExcelData = async () => {
    try {
      const response = await fetch("/publication/disability.xlsx");
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const sheetName = workbook.SheetNames[0]; // Get the first sheet
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // Get raw data with headers
      setExcelData(data);
    } catch (error) {
      console.error("Error loading Excel file:", error);
    }
  };

    // Function to export the table to Excel
    const downloadExcel = () => {
        const worksheet = XLSX.utils.aoa_to_sheet(excelData); // Convert data to sheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "table_data.xlsx"); // Download the file
      };
  const info = [
    {
      label: `${words.viz}`,
      component: (
        <div className="flex flex-col w-full gap-4 p-4 font-bold bg-white shadow-xl rounded-xl h-full">
          {visualsState?.viewWidth >= 1000 ? (
            <div
              className="embeded-dashboard-content m-auto min-w-[full]"
              style={{
                height: visualsState?.height,
                width: visualsState?.width,
              }}
            >
              <TableauEmbed url="https://public.tableau.com/views/TISSPDisabilityAnalysis/Disability?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link" />
            </div>
          ) : (
            <div
              className="embeded-dashboard-content m-auto block overflow-auto"
              style={{
                height: visualsState?.height,
                minWidth: visualsState?.maxWidth + 40,
              }}
            >
              <TableauEmbed url="https://public.tableau.com/views/TISSPDisabilityAnalysis/Disability?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link" />
            </div>
          )}
        </div>
      ),
    },
    {
      label: `${words.disabilityByNumber}`,
      component: (
        <div className="flex flex-col w-full gap-4 p-4 font-bold bg-white shadow-xl rounded-xl h-[1500px]">
      {/* Adding iframe to display content from the link */}
      <SiteEmbed url={"https://tisp.nbs.go.tz/visuals/dashboard/60"}/>
    </div>
      ),
    },
    {
      label: `${words.table}`,
      component: (
        <div className="flex flex-col w-full gap-4 p-4 font-bold bg-white shadow-xl rounded-xl h-full">
          <button
            className="px-4 py-2 mb-4 font-bold text-white bg-[#525252] rounded hover:bg-blue-700"
            onClick={downloadExcel}
          >
            Download Table
          </button>
          <div className="overflow-auto">
            {excelData.length > 0 ? (
              <HotTable
                data={excelData}
                colHeaders
                rowHeaders
                colWidths={100}
                width="100%"
                height="100vh"
                fixedRowsTop={2} // Makes the first row sticky
                licenseKey="non-commercial-and-evaluation"
                className="hot-table"
                cells={(row, col) => {
                    const cellProperties = {};
                    if (row === 0 || row === 1) {
                      cellProperties.renderer = (instance, td, row, col, prop, value) => {
                        td.innerHTML = value; // Default value rendering
                        td.style.fontWeight = "bold"; // Make text bold for the first two rows
                        td.style.backgroundColor = "#f4f4f4"; // Optional: Add background color
                      };
                    } else if (col === 2) { // Check if it's the third column
                      cellProperties.renderer = (instance, td, row, col, prop, value) => {
                        const formattedValue =
                          value && !isNaN(value) // Ensure the value is a number
                            ? parseFloat(value).toLocaleString("en-US") // Add thousand separator
                            : value; // Leave non-numeric values (e.g., header) untouched
                        td.innerHTML = formattedValue;
                        td.style.textAlign = "right"; // Align numbers to the right
                      };
                    }
                    return cellProperties;
                  }}
              />
            ) : (
              <p>No data loaded.</p>
            )}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full flex flex-col min-h-screen gap-4 px-4">
      <div className="flex items-center gap-2">
        {info.map((dt, index) => (
          <CencusButton
            key={index}
            name={dt.label}
            handleClick={() => handleSelectedButtonChange(index)}
            active={selectedButton === index}
          />
        ))}
      </div>
      {info[selectedButton].component}
    </div>
  );
}

export default DisabilityVisual;

const useTranslations = () => {
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  return {
    viz: language ? "Prevalence of Disability" : "Kuenea kwa Ulemavu",
    table: language ? "Table" : "Jedwali",
    disabilityByNumber: language ? "Persons with Disability" : "Watu wenye ulemavu",
    language,
  };
};
